export default {
  title: undefined,
  titleTemplate: '%s | NEW / OPEN',
  defaultTitle: 'Шоукейс-фестиваль New/Open',
  description:
    "Музыкант, попади на конкурс шоукейс фестиваля в Екатеринбурге и получи грант на свой проект. Твою работу оценят лучшие эксперты индустрии!",
  canonical: "https://newopen.su/",
  openGraph: {
    type: 'website',
    url: 'https://newopen.su/',
    locale: 'ru_RU',
    site_name: 'Шоукейс-фестиваль New/Open',
    title: "Шоукейс-фестиваль New/Open",
    description: "Музыкант, попади на конкурс шоукейс фестиваля в Екатеринбурге и получи грант на свой проект. Твою работу оценят лучшие эксперты индустрии!",
    images: [
      {
        url: "/images/homePage/cover1.png",
        width: 1620,
        height: 1527,
        alt: "Шоукейс-фестиваль New/Open"
      }
    ],
  },
};