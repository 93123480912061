const Colors = {
  black: '#000000',
  white: '#FFFFFF',
  turquoise: '#21CEB4',
  yellow: '#FAEA0A',
  lightGrey: '#EBEBEB',
  red: '#E63C24',
  gray: '#F4F4F4'
}

export default Colors
