import React, { useState } from 'react'
import Head from 'next/head'
import { AppProps } from 'next/app'
import { ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import { ReactQueryDevtools } from 'react-query/devtools'
import { defaultTheme } from 'styles/defaultTheme'
import Script from 'next/script'
import GlobalStyles from 'styles/globalStyles'
import Header from 'components/common/Header'
import { DefaultSeo } from 'next-seo'
import defaultSeo from '../next-seo.config'

const App = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 20 * 1000
          }
        }
      })
  )

  return (
    <>
      <Head>
        <link rel="icon" sizes="any" href="images/favicon.ico" />
        <link rel="preload" href="/fonts/Geometria-Bold.woff2" as="font" crossOrigin="" />
        <link rel="preload" href="/fonts/Geometria-Light.woff2" as="font" crossOrigin="" />
        <link rel="preload" href="/fonts/Geometria-Medium.woff2" as="font" crossOrigin="" />
        <link rel="preload" href="/fonts/Geometria-Regular.woff2" as="font" crossOrigin="" />
        <meta name="yandex-verification" content="29bc3813435a5ffe" />
        <meta name="google-site-verification" content="JjXS5C85ThWCvzc4dBjMu6ttFndjlo_ig0fYvM3n6Ug" />
        <link rel="alternate" hrefLang="ru" href="https://newopen.su/" />
        {/* <Script type="text/javascript" src="https://vk.com/js/api/openapi.js?169" /> */}
        {/* <!-- Yandex.Metrika counter --> */}
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
              m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
              (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
           
              ym(68820868, "init", {
                   clickmap:true,
                   trackLinks:true,
                   accurateTrackBounce:true,
                   webvisor:true
              })
              `
          }}
        />

        {/* <!-- Yandex.Metrika counter --> */}
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(70038058, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   })`
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `(!function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://vk.com/js/api/openapi.js?168",t.onload=function(){VK.Retargeting.Init("VK-RTRG-591246-7nHlr"),VK.Retargeting.Hit()},document.head.appendChild(t)}())`
          }}
        />

        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C2DQS285TE" />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
            
              gtag('config', 'G-C2DQS285TE');`
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `(!function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://vk.com/js/api/openapi.js?169",t.onload=function(){VK.Retargeting.Init("VK-RTRG-1098006-6GUMr"),VK.Retargeting.Hit()},document.head.appendChild(t)}())`
          }}
        />

        <noscript
          dangerouslySetInnerHTML={{
            __html: `
              <div>
                <img
                  src="https://vk.com/rtrg?p=VK-RTRG-591246-7nHlr"
                  style="position:fixed; left:-999px;"
                  alt=""
                />
              </div>
            `
          }}
        />

        <noscript
          dangerouslySetInnerHTML={{
            __html: `
              <div>
                <img
                  src="https://mc.yandex.ru/watch/70038058"
                  style="position:absolute; left:-9999px;"
                  alt=""
                />
              </div>
            `
          }}
        />

        <noscript
          dangerouslySetInnerHTML={{
            __html: `
              <div>
                <img
                  src="https://mc.yandex.ru/watch/68820868"
                  style="position:absolute; left:-9999px;"
                  alt=""
                />
              </div>
            `
          }}
        />

        <noscript
          dangerouslySetInnerHTML={{
            __html: `
              <div>
                <img
                  src="https://vk.com/rtrg?p=VK-RTRG-1098006-6GUMr"
                  style="position:fixed; left:-999px;"
                  alt=""
                />
              </div>
            `
          }}
        />
      </Head>
      <GlobalStyles />
      <Normalize />

      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ThemeProvider theme={defaultTheme}>
            <DefaultSeo {...defaultSeo} />
            <Component {...pageProps} />
          </ThemeProvider>
        </Hydrate>

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  )
}

export default App
