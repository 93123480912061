import { DefaultTheme } from 'styled-components'

export const defaultTheme: DefaultTheme = {
  palette: {
    primary: {
      main: '#DD3F3F',
      contrastText: '#FFFFFF'
    },
    text: {
      primary: '#FFFFFF',
      disabled: 'rgba(255, 255, 255, 0.3)',
      placeholder: 'rgba(255, 255, 255, 0.5)'
    },
    background: {
      primary: '#101010',
      secondary: '#151515'
    }
  },
  borderRadius: 15,
  boxShadow: {
    primary: '0px 2px 14px 0px rgba(0, 0, 0, .5)'
  }
}
