import { createGlobalStyle } from "styled-components";
import Threshold from "constants/threshold";
import Colors from "constants/colors";

export default createGlobalStyle`
  html {
    box-sizing: border-box;
    overflow-x:hidden;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  ul[class],
  ol[class] {
    padding: 0;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    overflow-x:hidden;
    margin: 0;
    font-family: 'Geometria';
    font-size: 14px;
    line-height: normal;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: ${Colors.black};
    background-color: ${Colors.white};

    @media (min-width: ${Threshold.phone}) {
      font-size: 20px;
    }
  }

  ul[class],
  ol[class] {
    list-style: none;
  }

  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  img {
    max-width: 100%;
    display: block;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
    text-align: center;
  }

  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
    text-align: center;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    text-align: start;
  }

  @media (min-width: ${Threshold.phone}) {
    h1 {
      font-size: 35px;
      line-height: 50px;
    }

    h2 {
      font-size: 35px;
      line-height: 50px;
    }

    h3 {
      font-size: 16px;
      line-height: 35px;
      text-align: center;
    }
  }

  @font-face {
    font-family: "Geometria";
    src: url("/fonts/Geometria-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Geometria";
    src: url("/fonts/Geometria-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Geometria";
    src: url("/fonts/Geometria-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  .lectureModal_Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .lectureModal_Content {
    position: absolute;
    width: 330px;
    height: 200px;
    padding: 0;
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
    border-radius: 0;
    border: none;
    background: none;
    
    &:focus {
      outline: none;
    }
    
    @media (min-width: ${Threshold.phone}) {
      width: 600px;
      height: 325px;
    }
    
    @media (min-width: ${Threshold.tablet}) {
      width: 800px;
      height: 432px;
    }
  }
`;
